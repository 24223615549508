<template>
  <el-container>
    <Header pageName="帮助管理"></Header>
    <el-main>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="帮助列表" name="1">
          <el-button type="primary" size="small" @click="add">添加帮助</el-button>
          <el-form class="el-form-search" label-width="90px">
            <el-form-item label="标题：">
              <el-input v-model="title" placeholder="请输入帮助标题" size="small"></el-input>
            </el-form-item>
            <el-form-item label=" " label-width="20px">
              <el-button type="primary" size="small" @click="searchHelp">搜索</el-button>
              <el-button plain size="small" @click="cancelSearchHelp">清空搜索条件</el-button>
            </el-form-item>
          </el-form>
          <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
            <template slot="empty">
              <No />
            </template>
            <el-table-column prop="sort" label="组内权重" width="150"></el-table-column>
            <el-table-column prop="title" label="帮助标题" align="center"></el-table-column>
            <el-table-column prop="category_name" label="帮助分类" align="center"></el-table-column>
            <el-table-column prop="create_time" label="发布时间" align="center">
              <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
            </el-table-column>
            <el-table-column prop="money" label="操作" align="center">
              <template v-slot="{ row }">
                <el-button @click="edit(row)" size="small" type="text">编辑</el-button>
                <el-button @click="del(row)" size="small" type="text">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-dialog title="添加/编辑帮助" :visible.sync="is_showHelpAdd" width="700px">
            <el-form ref="form" :model="addHelpForm" :rules="rules2" label-width="120px">
              <el-form-item label="帮助标题：" prop="title">
                <el-input v-model="addHelpForm.title"></el-input>
              </el-form-item>
              <el-form-item label="帮助分类：" prop="category">
                <el-cascader v-model="addHelpForm.category" :options="helpList" :props="{ value: 'id', label: 'title', children: 'child' }" @change="handleChange"></el-cascader>
              </el-form-item>
              <el-form-item label="组内权重：">
                <el-input v-model="addHelpForm.sort"></el-input>
              </el-form-item>
              <el-form-item label="帮助内容：" prop="content">
                <RichText :richTxt="addHelpForm.content" :is_site="0" width="540" height="300" @soninfo="val => (addHelpForm.content = val)"></RichText>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="is_showHelpAdd = !1">取 消</el-button>
              <el-button type="primary" @click="addHelp">确 定</el-button>
            </span>
          </el-dialog>
        </el-tab-pane>
        <el-tab-pane label="帮助分类" name="2">
          <el-button style="margin-bottom:10px;" type="primary" size="small" @click="addType">添加分类</el-button>
          <el-table :data="helpList" :tree-props="{ children: 'child', hasChildren: 'hasChildren' }" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
            <template slot="empty">
              <No />
            </template>
            <el-table-column prop="title" label="分类名称" align="left"></el-table-column>
            <el-table-column prop="sort" label="组内权重" width="150"></el-table-column>
            <el-table-column prop="create_time" label="发布时间" align="center">
              <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
            </el-table-column>
            <el-table-column prop="money" label="操作" align="center">
              <template v-slot="{ row }">
                <el-button @click="editHelpType(row)" size="small" type="text">编辑</el-button>
                <el-button @click="delHelpType(row)" size="small" type="text">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-dialog title="添加/编辑分类" :visible.sync="is_showHelpTypeAdd" width="600px">
            <el-form ref="form" :model="addHelpTypeForm" :rules="rules" label-width="120px">
              <el-form-item label="分类名称：" prop="title">
                <el-input v-model="addHelpTypeForm.title"></el-input>
              </el-form-item>
              <el-form-item label="上级分类：">
                <el-select v-model="addHelpTypeForm.pid" placeholder="请选择">
                  <el-option v-for="(item, index) in helpList" :key="index" :label="item.title" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="组内权重：">
                <el-input v-model="addHelpTypeForm.sort"></el-input>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="is_showHelpTypeAdd = !1">取 消</el-button>
              <el-button type="primary" @click="addHelpType">确 定</el-button>
            </span>
          </el-dialog>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>
</template>

<script>
import Header from './components/header';
import { getDateformat } from '@/util/getDate';
import RichText from '@/components/richText';
import _ from 'lodash';
export default {
  components: {
    Header,
    RichText,
  },
  data() {
    return {
      activeName: '1',
      title: '',
      page: 1,
      rows: 10,
      total_number: 0,
      page2: 1,
      rows2: 10,
      total_number2: 0,
      list: [],
      helpList: [],
      is_showHelpTypeAdd: !1,
      is_showHelpAdd: !1,
      addHelpForm: {
        title: '',
        category: '',
        content: '',
        sort: '',
        is_show: 1,
      },
      addHelpTypeForm: {
        title: '',
        sort: '',
        pid: '',
        is_show: 1,
      },
      rules: {
        title: [
          {
            required: true,
            message: '请输入分类名称',
            trigger: 'blur',
          },
        ],
      },
      rules2: {
        title: [
          {
            required: true,
            message: '请输入帮助标题',
            trigger: 'blur',
          },
        ],
        category: [
          {
            required: true,
            type: 'array',
            message: '请选择分类',
            trigger: 'blur',
          },
        ],
        content: [
          {
            required: true,
            message: '请输入帮助内容',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {
    this.getList();
    this.getHelpType();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    getList() {
      this.$axios
        .post(this.$api.admin.articleList, {
          page: this.page,
          rows: this.rows,
          title: this.title,
          type: 4,
        })
        .then(res => {
          if (res.code == 0) {
            this.list = res.result.list;
            this.total_number = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    searchHelp() {
      this.page = 1;
      this.getList();
    },
    cancelSearchHelp() {
      this.page = 1;
      this.row = 10;
      this.title = '';
      this.getList();
    },
    add() {
      this.addHelpForm = {
        title: '',
        category: '',
        content: '',
        sort: '',
        is_show: 1,
      };
      this.is_showHelpAdd = !0;
    },
    edit(row) {
      let that = this;
      this.addHelpForm.title = row.title;
      this.addHelpForm.category = [row.category];
      this.addHelpForm.content = row.content;
      this.addHelpForm.sort = row.sort;
      this.addHelpForm.id = row.id;
      this.is_showHelpAdd = !0;
      this.helpList.map(item => {
        item.child.map(list => {
          if (list.id == row.category) {
            that.addHelpForm.category = [item.id, row.category];
          }
        });
      });
    },
    del(row) {
      this.$axios
        .post(this.$api.admin.articleDel, {
          id: row.id,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('删除成功');
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    addHelp() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let addHelpForm = _.cloneDeep(this.addHelpForm);
          addHelpForm.type = 4;
          if (addHelpForm.category) {
            addHelpForm.category.length > 1 ? (addHelpForm.category = addHelpForm.category[1]) : (addHelpForm.category = addHelpForm.category[0]);
          }
          let url = this.addHelpForm.id ? this.$api.admin.articleEdit : this.$api.admin.articleAdd;
          this.$axios.post(url, addHelpForm).then(res => {
            if (res.code == 0) {
              this.$message.success(this.addHelpForm.id ? '编辑成功' : '添加成功');
              this.is_showHelpAdd = !1;
              this.getList();
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    getHelpType() {
      this.$axios
        .post(this.$api.admin.helpTypeList, {
          page: this.page2,
          rows: this.rows2,
        })
        .then(res => {
          if (res.code == 0) {
            let list = res.result.list;
            for (let i in list) {
              if (list[i].child.list.length) {
                list[i].child = list[i].child.list;
              } else {
                delete list[i].child;
              }
            }
            this.helpList = list;
            this.total_number = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    addType() {
      this.addHelpTypeForm = {
        title: '',
        sort: '',
        pid: '',
        is_show: 1,
      };
      this.is_showHelpTypeAdd = !0;
    },
    addHelpType() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let addHelpTypeForm = Object.assign({}, this.addHelpTypeForm);
          if (!addHelpTypeForm.pid) delete addHelpTypeForm.pid;
          this.$axios.post(this.$api.admin.addHelpType, addHelpTypeForm).then(res => {
            if (res.code == 0) {
              this.$message.success('添加成功');
              this.is_showHelpTypeAdd = !1;
              this.getHelpType();
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    editHelpType(row) {
      this.addHelpTypeForm.title = row.title;
      this.addHelpTypeForm.sort = row.sort;
      if (row.pid) {
        this.addHelpTypeForm.pid = row.pid;
      }
      this.is_showHelpTypeAdd = !0;
    },
    delHelpType(row) {
      this.$axios.post(this.$api.admin.delHelpType, { id: row.id }).then(res => {
        if (res.code == 0) {
          this.$message.success('删除成功');
          this.getHelpType();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-container {
  flex-direction: column;
  .el-main {
    background: #fff;
    .el-form {
      margin-top: 10px;
    }
    .el-table {
      /deep/ .el-input__inner {
        border-color: #fff;
      }
      /deep/ .el-input__inner:focus {
        border-color: #409eff;
      }
      .edit {
        display: flex;
        align-items: center;
        span {
          margin-right: 5px;
        }
        i {
          font-size: 17px;
        }
      }
    }
  }
}
</style>